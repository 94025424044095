import { APISettings } from '../config.js';
import { ElMessage } from 'element-plus'

export default {
    
    list(data) {
        const token = localStorage.getItem('token')

        return fetch( APISettings.baseURL + '/news/list?' + (new URLSearchParams(data)).toString(), {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then( function( response ){
            if( response.status != 200 ){
                ElMessage({
                    showClose: true,
                    message: 'Oops, bad request',
                    type: 'error',
                })
                throw response.status;
            }else{
                return response.json();
            }
        });
    }
}