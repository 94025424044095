<template>
  <div class="dark">
    <el-row style="margin: 20px">
      <el-col :span="5">
        <el-select v-model="typeValue" placeholder="Select type" style="min-width: 200px" clearable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-input
          v-model="input"
          class="col-2"
          placeholder="Please input search content..."
        />
      </el-col>

      <el-col :span="6">
        <div class="grid-content ep-bg-purple" />
      </el-col>
      <el-col :span="1">
        <el-button type="warning" circle @click="exitDialog = true">
          <el-icon>
            <SwitchButton />
          </el-icon>
        </el-button>
      </el-col>
    </el-row>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="type" label="资讯来源" min-width="100" >

      <template #default="scope">
        <div>
          <span>{{ typeEnum[scope.row.type] }}</span>
        </div>
      </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" min-width="280" show-overflow-tooltip />
      <el-table-column prop="region" label="所在区域"  />
      <el-table-column prop="date" label="资讯发布日期"  />
        <el-table-column fixed="right" label="Operations" width="120">
        <template #default="scope">
            <el-button link type="primary" size="small" @click="openLink(scope.row)"
            >Open</el-button
            >
        </template>
        </el-table-column>
    </el-table>
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->

    <el-dialog
        v-model="exitDialog"
        title="Warning"
        width="25%"
        align-center
    >
        <span>Are you sure you want to log out?</span>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="exitDialog = false">Cancel</el-button>
            <el-button type="primary" @click="logout"
            >Confirm</el-button
            >
        </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  created() {
    if (!localStorage.getItem("user")) {
      this.$router.push("/login");
    }
  },
};
</script>

<script setup>
import { ref, watch } from "vue";
import { useRouter } from 'vue-router'
import newsApi from "../api/resources/news.js";

const input = ref("");
const typeValue = ref("");
const tableData = ref([{
    id: 0,
    type: 1,

}]);
const exitDialog = ref(false);
const router = useRouter()
const typeEnum = {
    1: "衢州市公共资源交易中心",
    2: "丽水市公共资源交易网"
}

let req = {
    num: 999
}

const options = [
  {
    value: "1",
    label: "衢州市公共资源交易中心",
  },
  {
    value: "2",
    label: "丽水市公共资源交易网",
  },
];

// 监听
watch(
    () => typeValue.value,
    (val)=>{
        if (val) {
            req = {
                ...req,
                type: val
            }
        } else {
            delete req.type
        }
        getNews()
    }
)

watch(
    () => input.value,
    (val)=>{
        if (val) {
            req = {
                ...req,
                title: val
            }
        } else {
            delete req.title
        }
        getNews()
    }
)

const getNews = async () => {
  const resp = await newsApi.list(req);
  tableData.value = resp.data.list
  return resp.data.list
}

// 获取资讯
getNews()

const openLink = (v) => {
    window.open(v.link);
}

const logout = () => {
    localStorage.removeItem('user');

    router.push('/login')
}
</script>
